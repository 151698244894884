<template>
    <div>
        <form @submit.prevent="isEdit ? updateCriteriaConfigs() : createCriteriaSet()">
            <div class="card-body">
                <!-- Success Banner -->
                <div v-if="updateSuccess" class="alert alert-success mt-3">
                    Criteria Set {{ isEdit ? 'updated' : 'created' }} successfully.
                </div>

                <!-- Criteria Set Form -->
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="name" required>Criteria Set Name</label>
                            <input type="text" id="name" class="form-control" v-model="criteriaSet.name" required>
                        </div>
                    </div>
                </div>

                <!-- Criteria Configs -->
                <div class="mb-2">
                    <label for="criteria" required class="me-2">Criteria</label>
                </div>

                <!-- Column Headers - Only visible on md screens and up -->
                <div class="row mb-2 d-none d-md-flex">
                    <div class="col-md-6">
                        <strong>Parameter</strong>
                    </div>
                    <div class="col-md-2">
                        <strong>Rule</strong>
                    </div>
                    <div class="col-md-2">
                        <strong>Value</strong>
                    </div>
                    <div class="col-md-1 text-center">
                        <div class="d-flex align-items-center justify-content-center">
                            <strong>Special Values</strong>
                            <i class="fa-solid fa-circle-info tooltip2 ms-1" data-bs-toggle="tooltip" title="When toggled on, special values (<strong>N/A</strong>, <strong>NONE</strong>) and credit scores outside the <strong>100-999</strong> range will pass if they don't match the condition."></i>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <strong>Remove</strong>
                    </div>
                </div>

                <div v-for="(config, index) in criteriaConfigs" :key="index">
                    <!-- Conditionally apply a border class to the row's container based on to_be_deleted -->
                    <div :class="{'border': config.to_be_deleted, 'border-danger': config.to_be_deleted, 'my-2': config.to_be_deleted, 'p-2': config.to_be_deleted}">
                        <div v-if="config.to_be_deleted">
                            <strong>Marked for deletion</strong>
                        </div>

                        <!-- Desktop layout (md screens and up) -->
                        <div class="row mb-3 d-none d-md-flex">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <v-select v-model="config.parameter"
                                            :options="attributes"
                                            label="name"
                                            :reduce="attribute => attribute.slug"
                                            placeholder="Select an option or type to search"
                                            :clearable="false"
                                            required>
                                    </v-select>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <select class="form-select" v-model="config.rule" required>
                                        <option value="" disabled>Select a rule</option>
                                        <option value="==">Equals</option>
                                        <option value="!=">Does Not Equal</option>
                                        <option value=">">Greater Than</option>
                                        <option value="<">Less Than</option>
                                        <option value=">=">Greater Than or Equal To</option>
                                        <option value="<=">Less Than or Equal To</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <input type="text" class="form-control" v-model="config.value" required :disabled="config.to_be_deleted">
                                </div>
                            </div>
                            <div class="col-md-1 d-flex align-items-center justify-content-center">
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" role="switch" v-model="config.special_values_pass" :id="'specialValueToggle' + index" :disabled="config.to_be_deleted">
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div>
                                    <button v-if="!config.to_be_deleted"
                                            type="button"
                                            class="btn btn-danger btn-block"
                                            @click="removeCriteriaConfig(index)">
                                        <i class="fas fa-trash-alt"></i>
                                    </button>
                                    <button v-else
                                            type="button"
                                            class="btn btn-danger btn-block"
                                            @click="cancelDeleteCriteriaConfig(index)">
                                        <i class="fas fa-undo-alt" style="font-size: 1.5rem; cursor: pointer;"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <!-- Mobile layout (smaller than md screens) -->
                        <div class="d-md-none mb-4">
                            <div class="form-group mb-2">
                                <label>Parameter</label>
                                <v-select v-model="config.parameter"
                                        :options="attributes"
                                        label="name"
                                        :reduce="attribute => attribute.slug"
                                        placeholder="Select an option or type to search"
                                        :clearable="false"
                                        required>
                                </v-select>
                            </div>

                            <div class="form-group mb-2">
                                <label>Rule</label>
                                <select class="form-select" v-model="config.rule" required>
                                    <option value="" disabled>Select a rule</option>
                                    <option value="==">Equals</option>
                                    <option value="!=">Does Not Equal</option>
                                    <option value=">">Greater Than</option>
                                    <option value="<">Less Than</option>
                                    <option value=">=">Greater Than or Equal To</option>
                                    <option value="<=">Less Than or Equal To</option>
                                </select>
                            </div>

                            <div class="form-group mb-2">
                                <label>Value</label>
                                <input type="text" class="form-control" v-model="config.value" required :disabled="config.to_be_deleted">
                            </div>

                            <div class="form-group mb-2">
                                <div class="d-flex align-items-center">
                                    <label class="me-2">Special Values</label>
                                    <i class="fa-solid fa-circle-info tooltip2" data-bs-toggle="tooltip" title="When toggled on, special values (<strong>N/A</strong>, <strong>NONE</strong>) and credit scores outside the <strong>100-999</strong> range will pass if they don't match the condition."></i>
                                </div>
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" role="switch" v-model="config.special_values_pass" :id="'mobileSpecialValueToggle' + index" :disabled="config.to_be_deleted">
                                </div>
                            </div>

                            <div class="mt-2">
                                <button v-if="!config.to_be_deleted"
                                        type="button"
                                        class="btn btn-danger"
                                        @click="removeCriteriaConfig(index)">
                                    <i class="fas fa-trash-alt me-1"></i> Remove
                                </button>
                                <button v-else
                                        type="button"
                                        class="btn btn-danger"
                                        @click="cancelDeleteCriteriaConfig(index)">
                                    <i class="fas fa-undo-alt me-1"></i> Undo Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <button type="button" class="btn btn-sm btn-secondary" @click="addCriteriaConfig">Add Additional Criteria</button>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col">
                        <button type="submit" class="btn btn-primary">Save Criteria Set</button>
                    </div>
                    <div class="col text-end" v-if="isEdit && criteriaConfigData && criteriaConfigData.slug">
                        <button type="button" class="btn btn-danger" @click="showDeleteWarning">
                            <i class="fa-solid fa-trash fa-fw"></i> Delete Criteria Set
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
    components: { vSelect },
    props: {
        isEdit: {
            type: Boolean,
            default: false
        },
        criteriaConfigData: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            updateSuccess: false,
            criteriaSet: {
                name: this.isEdit && this.criteriaConfigData ? this.criteriaConfigData.name : ''
            },
            criteriaConfigs: [],
            attributes: [],
            specialValues: {
                'N/A': 'N/A',
                'Any Value': '*'
            }
        };
    },
    created() {
        if (this.isEdit && this.criteriaConfigData && this.criteriaConfigData.criteria_configs) {
            // Initialize criteriaConfigs for editing mode
            this.criteriaConfigs = this.criteriaConfigData.criteria_configs.map(config => {
                return {
                    ...config,
                    // Convert any truthy value to boolean true (1, '1', true)
                    special_values_pass: Boolean(config.special_values_pass)
                };
            });
        } else {
            // Initialize with one empty config for creating mode
            this.criteriaConfigs = [
                { parameter: '', rule: '', value: '', special_values_pass: false }
            ];
        }
    },
    mounted() {
        this.fetchAttributes();
        // Initialize tooltips
        this.initTooltips();
    },
    updated() {
        // Reinitialize tooltips when the DOM updates
        this.initTooltips();
    },
    methods: {
        initTooltips() {
            // Initialize Bootstrap tooltips
            const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
            tooltipTriggerList.map(function (tooltipTriggerEl) {
                return new bootstrap.Tooltip(tooltipTriggerEl, {
                    html: true  // Enable HTML in tooltips
                });
            });
        },
        createCriteriaSet() {
            const configsToSend = this.criteriaConfigs.map(config => {
                return {
                    parameter: config.parameter,
                    rule: config.rule,
                    value: config.value,
                    special_values_pass: config.special_values_pass
                };
            });

            axios.post('/criteria', { criteria_set: this.criteriaSet, criteria_configs: configsToSend })
                .then(response => {
                    this.updateSuccess = true;
                    setTimeout(() => {
                        window.location.href = '/criteria';
                    }, 1000);
                })
                .catch(error => {
                    // Handle any errors here
                    console.error('Error creating criteria set:', error);
                });
        },
        updateCriteriaConfigs() {
            const configsToSend = this.criteriaConfigs.map(config => {
                return {
                    parameter: config.parameter,
                    rule: config.rule,
                    value: config.value,
                    special_values_pass: config.special_values_pass,
                    slug: config.slug,
                    to_be_deleted: config.to_be_deleted
                };
            });

            const criteriaSlug = this.criteriaConfigData.slug;

            axios.post(`/criteria/update/${criteriaSlug}`, { criteria_set: this.criteriaSet, criteria_configs: configsToSend })
                .then(response => {
                    this.updateSuccess = true;
                    setTimeout(() => {
                        window.location.href = '/criteria';
                    }, 1000);
                })
                .catch(error => {
                    // Handle error
                    console.error('Error updating criteria set:', error);
                });
        },
        addCriteriaConfig() {
            this.criteriaConfigs.push({
                parameter: '',
                rule: '',
                value: '',
                special_values_pass: false
            });
        },
        removeCriteriaConfig(index) {
            const config = this.criteriaConfigs[index];

            if (config.slug) {
                // If criteriaConfig.slug is set, mark the config as to_be_deleted
                config.to_be_deleted = true;
            } else {
                // If criteriaConfig.slug is not set, remove the config from the array
                this.criteriaConfigs.splice(index, 1);
            }
        },
        cancelDeleteCriteriaConfig(index) {
            const config = this.criteriaConfigs[index];

            if (config.slug && config.to_be_deleted) {
                // If criteriaConfig.slug is set and to_be_deleted is true, revert to_be_deleted to false
                config.to_be_deleted = false;
            }
        },
        fetchAttributes() {
            axios.get('/get-attributes-for-user')
                .then(response => {
                    this.attributes = response.data;
                })
                .catch(error => {
                    console.error('Error fetching attributes:', error);
                });
        },
        showDeleteWarning() {
            // Display a confirmation dialog to the user
            const confirmation = window.confirm('Are you sure you want to delete this criteria set?');

            if (confirmation) {
                // The user clicked "OK" in the confirmation dialog, proceed with deletion
                this.deleteCriteria();
            }
        },
        deleteCriteria() {
            const criteriaSlug = this.criteriaConfigData.slug;

            if (criteriaSlug) {
                // Make an API request to delete the criteria
                axios.delete(`/criteria/delete/${criteriaSlug}`)
                    .then(response => {
                        // Handle the response here, if needed
                        this.updateSuccess = true;
                        setTimeout(() => {
                            window.location.href = '/criteria';
                        }, 1000);
                    })
                    .catch(error => {
                        // Handle error
                        console.error('Error deleting criteria set:', error);
                    });
            }
        }
    }
};
</script>

